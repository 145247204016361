
import {routerPaths} from '../../../router/routerPaths'

const GetPdfFile = () => import(/* webpackChunkName: "group-get-file-html" */ './components/GetPdfFile/GetPdfFile.vue')

const GetHtmlFile = () => import(/* webpackChunkName: "group-get-file-html" */ './components/GetHtmlFile/GetHtmlFile.vue')


export const GetFileRoutes = [
  {
    path: routerPaths.getHtmlFileId,
    component: GetHtmlFile,

    pageTitle: 'common_getFileTitle'
  },

  {
    path: routerPaths.getPdfFileId,
    component: GetPdfFile,

    pageTitle: 'common_getFileTitle'
  },

]
