import {API} from "../../axios/axiosMainUrl";

export const customLimits = {
  state: {
    customLimits: [],
    customLimitsItem: {},
    customLimitsCommonList: [],
    customLimitsBtn: false,
    customLimitsLoading: true,
    nextCustomLimitsPage: false,
    customLimitsForPage: 25,
    customLimitsFilter: '',
  },

  mutations: {
    setCustomLimitsFilter: (state, payload) => {
      state.customLimitsFilter = payload
    },

    setCustomLimits: (state, payload) => {
      if(state.nextCustomLimitsPage) {
        state.customLimits = state.customLimits.concat(payload.customLimits)
      } else {
        state.customLimits = payload.customLimits
      }
    },

    setCustomLimitsCommonList: (state, payload) => {
      state.customLimitsCommonList = payload.customLimitsCommonList
    },

    setNextCustomLimitsPage: (state, payload) => {
      state.nextCustomLimitsPage = payload
    },

    setCustomLimitsItem: (state, payload) => {
      state.customLimitsItem = payload.customLimitsItem
    },

    setCustomLimitsLoadingStart: (state) => {
      state.customLimitsLoading = true
    },

    setCustomLimitsLoadingEnd: (state) => {
      state.customLimitsLoading = false
    },

    changeCustomLimitsBtn: (state) => {
      state.customLimitsBtn = true
    },
    successCustomLimitsBtn: (state) => {
      state.customLimitsBtn = false
    },
  },

  getters: {
    getCustomLimitsFilter: state => {
      return state.customLimitsFilter
    },

    getCustomLimitsForPage: state => {
      return state.customLimitsForPage
    },

    getCustomLimits: state => {
      return state.customLimits
    },

    getCustomLimitsLoading: state => {
      return state.customLimitsLoading
    },


    getNextCustomLimitsPage: state => {
      return state.nextCustomLimitsPage
    },

    getCustomLimitsItem: state => {
      return state.customLimitsItem
    },

    getCustomLimitsCommonList: state => {
      return state.customLimitsCommonList
    },

    getCustomLimitsBtn: state => {
      return state.customLimitsBtn
    },
  },

  actions: {

    async fetchCustomLimitsWithPermissions({commit, getters}, filter = '') {
      if(!getters.getNextCustomLimitsPage)
        commit('setCustomLimitsLoadingStart')
      try {
        return await API.get(`/delivery-country-customs${filter}`).then((response) =>{
          commit('setCustomLimits',{customLimits: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setCustomLimitsCommonList',{customLimitsCommonList: response.data.data})
          commit('setCustomLimitsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCustomLimitsLoadingEnd');
        return e;
      }
    },

    async fetchCustomLimits({commit, getters}, filter = '') {
      if(!getters.getNextCustomLimitsPage)
        commit('setCustomLimitsLoadingStart')
      try {
        return await API.get(`/delivery-country-customs/public-list${filter}`).then((response) =>{
          commit('setCustomLimits',{customLimits: response.data.data.data})
          // commit('setCustomLimitsCommonList',{customLimitsCommonList: response.data.data})
          commit('setCustomLimitsCommonList',{customLimitsCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setCustomLimitsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCustomLimitsLoadingEnd');
        return e;
      }
    },

    async getCustomLimits({commit}, filter) {
      commit('setCustomLimitsLoadingStart');
      try {
        return await API.get(`/delivery-country-customs/public-list${filter}`).then((response) =>{
          console.log(response)
          commit('setCustomLimitsItem', {customLimitsItem: response.data.data.data[0]})
          commit('setCustomLimitsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setCustomLimitsLoadingEnd');
        return e;
      }
    },

    async createCustomLimits({commit}, data) {
      commit('changeCustomLimitsBtn');
      try {
        return await API.post(`/customLimits`, data).then((response) =>{
          commit('successCustomLimitsBtn');
          return response;
        });
      } catch (e) {
        commit('successCustomLimitsBtn');
        return e;
      }
    },

    async updateCustomLimits({commit}, {id, data}) {
      commit('changeCustomLimitsBtn');
      try {
        return await API.post(`/delivery-country-customs/${id}`, data).then((response) =>{
          commit('successCustomLimitsBtn');
          return response;
        });
      } catch (e) {
        commit('successCustomLimitsBtn');
        return e;
      }
    },

    async deleteCustomLimits({commit}, id) {
      try {
        return await API.delete(`/customLimits/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successCustomLimitsBtn');
        return e;
      }
    },

  }
}
