<template>
  <div class="custom-drop-zone"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit"
            @click="editTranslate([
            'common_dropFileHere',
            'login_or',
            'common_Choose',
            'common_upTo',
            'common_sizeError',
            ])"></span>


    <file-upload :capture="captureFlag" :drop="true" :maximum="maxCount" :input-id="ifFileField" v-model="files" :multiple="multiple" :accept="acceptZone" @input="change" @input-filter="inputFilter">
      <div class="dz-message"
          :class="{ 'd-none' : (!multiple && files.length !== 0)}"
      >
        <div class="custom-drop-zone__label">
          <div class="custom-drop-zone__ico">
            <DropFileIco/>
          </div>
          <div class="custom-drop-zone__title">
            {{$t('common_dropFileHere.localization_value.value')}}
            <span>{{$t('login_or.localization_value.value')}}</span>
          </div>
          <div class="custom-drop-zone__btn">
            {{$t('common_Choose.localization_value.value')}}
          </div>
          <div class="custom-drop-zone__caption">
            {{$t('common_upTo.localization_value.value')}} {{maxSize ? maxSize : '3'}}Mb
          </div>
        </div>
      </div>

    </file-upload>


    <div class="dz-preview dz-processing dz-image-preview" v-for="(file, key) in files" :key="key">
      <div class="dz-image">
        <img data-dz-thumbnail="" alt="arr.png" :src="file.blob">
      </div>
      <div class="dz-details">
        <div class="dz-size"><span data-dz-size=""><strong>{{file.size / 1000}}</strong> KB</span></div>
        <div class="dz-filename"><span data-dz-name="">{{file.name}}</span></div>
      </div>

      <div class="dz-progress"  v-if="!file.success && file.size / 1000000 <= maxSize">
        <span class="dz-upload" data-dz-uploadprogress=""></span></div>
      <div class="dz-success-mark" v-if="file.success">

      </div>

      <div class="dz-error-mark" v-if="file.error || file.size / 1000000 > maxSize || key > maxCount - 1">

      </div>
      <a v-if="!file.success" class="dz-remove" href="javascript:void(0)" data-dz-remove="" @click="removeItem(key)">Cancel upload</a>
      <div class="text-file-error default-input-wrap__error" v-if="file.errorTxt">{{file.errorTxt[0]}}</div>
      <div class="text-file-error default-input-wrap__error" v-if="key > maxCount - 1">MAX COUNTS FILE {{maxCount}}</div>
      <div class="text-file-error default-input-wrap__error" v-if="file.maxSizeError">{{$t('common_sizeErrorNew.localization_value.value')}} {{maxSize}} mb</div>
<!--      {{$t('common_sizeError.localization_value.value')}}-->
    </div>

    <span class="textarea-default__error error-field" v-if="error && errorTxt && !maxSizeError">{{errorTxt}}</span>
    <!--<span class="textarea-default__error error-field" v-if="maxSizeError">{{$t('common_sizeError.localization_value.value')}}</span>-->
  </div>
</template>

<script>
  import DropFileIco from '../../../../public/img/common/drop-file-icon.svg?inline'

  export default {
    name: "DropZone",

    components: {
      // vueDropzone: vue2Dropzone,
      DropFileIco,
    },


    props: {
      maxCount: {
        type: Number,
        default: 10000
      },
      parentFiles: {
        type: Array,
      },
      accept: {
        type: String,
      },
      maxSize: {
        type: [String, Number],
        default: '5',
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      error: {
        type: Boolean,
      },
      errorTxt: {
        type: String,
      },
      captureFlag: {
        type: [Boolean, String],
        default: false,
      },
    },

    data() {
      return {
        files: this.parentFiles,

        id: null,
        ifFileField: 'file-' + this._uid,

        acceptZone: this.accept ? this.accept : 'image/x-png,image/gif,image/jpeg,application/pdf',
        maxSizeError: false,
      }
    },

    watch: {
      parentFiles: function (newVal) {
        this.files = newVal
      },
    },

    mounted() {
      this.id = this._uid
    },

    methods: {

      change() {
        this.checkSizeFile()
        this.checkCountsFile()
        this.$emit('changeImg', this.files, this.maxSizeError)
      },

      checkCountsFile() {
        this.files.map((item, index) => {
          if(index + 1 > this.maxCount) {
            item.maxCount = true
          }
        })
      },

      checkSizeFile(){
        this.maxSizeError = false

        this.files.map(item => {
          if(item.size / 1000000 > this.maxSize) {
            this.maxSizeError = true
            item.maxSizeError = true
          }
        })

        if(this.maxSizeError) {
          return false
        }
      },

      removeItem(id) {
        this.files.splice(id, 1)

        this.checkSizeFile()

        this.$emit('changeImg', this.files, this.maxSizeError)
      },

      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // Add file

          // Will not be added to files
          if (!/\.(jpeg|jpe|jpg|png|pdf|svg|xls|xlsx|bmp|dxf|dst|ai|webm|mkv|flv|avi|mov|wmv|amv|mp4|zip|stl|gif|xml|doc|docx|psd)$/i.test(newFile.name)) {
            return prevent()
          }

          // Create the 'blob' field for thumbnail preview
          newFile.blob = ''
          let URL = window.URL || window.webkitURL
          if (URL && URL.createObjectURL) {
            newFile.blob = URL.createObjectURL(newFile.file)
          }
        }

        if (newFile && oldFile) {
          // Update file

          // Increase the version number
          if (!newFile.version) {
            newFile.version = 0
          }
          newFile.version++
        }

        if (!newFile && oldFile) {
          // Remove file

          // Refused to remove the file
          // return prevent()
        }
      }
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .file-uploads{
    width: 100%;
  }

  .custom-drop-zone {
    position: relative;

    * {
      font-family: Roboto, sans-serif;
    }

    &.ui-no-valid {
      .dz-message {
        border: 2px solid $orange;
      }
    }

    .dz-message {
      width: 100%;
      margin-top: 0;
      margin-bottom: 15px;
      border: 2px dashed $borderBrown;
      box-sizing: border-box;
      padding: 30px 15px 15px;

      border-radius: 5px;
    }

    .vue-dropzone {
      //background: $mainBg;
      background: transparent;
      border: none;
      box-sizing: border-box;
      padding: 0;
    }

    .dropzone.dz-started .dz-message {
      display: flex;
    }

    &__label {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__ico {
      margin-bottom: 15px;
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $brown;
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;

      span {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $borderBrown;
      }
    }

    label {
      cursor: pointer;
    }

    &__btn {
      color: white;
      background: $accent;
      border-radius: 5px;
      padding: 10px 25px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 8px;
    }

    &__caption {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $borderBrown;
    }

    .dz-preview {
      display: flex ;
      min-height: auto ;
      margin: 0;
      align-items: center;
      vertical-align: initial ;
      position: relative ;
      margin-bottom: 7px ;


      @include for-680{
        margin-bottom: 20px;
      }

      > * {
        position: relative ;
        top: auto ;
        left: auto ;
        right: auto ;
        bottom: auto ;

      }

      > .text-file-error{
        position: absolute ;
        /*bottom: -8px;*/
        right: 0px;

        bottom: auto;
        top: calc(100% + 2px);
        line-height: 0.95;

        & + .text-file-error{
          bottom: -20px;
        }
      }
    }

    .dz-image {
      display: none ;
    }

    .dz-details {
      opacity: 1;
      padding: 0;
      display: flex;
      width: 100%;
      background: transparent;
      padding-top: 2px;
      margin-right: 20px;

      .dz-size {
        margin-bottom: 0;
        order: 2;
        max-width: 100px;
        width: 100%;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $brown;
      }

      .dz-filename {
        width: 100%;
        order: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $black;
        word-break: break-all;
        overflow: hidden;
        margin-right: 20px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
      }

      order: 2;
    }

    .dz-remove {
      order: 1;
      border: 0;
      font-size: 0;
      width: 35px;
      min-width: 35px;
      display: flex;
      height: 35px;
      margin-left: 0;
      padding: 0;
      transition: .2s;
      opacity: 1;


      background-image: url("../../../assets/img/UI-group/delete-fill-icon.svg");
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        opacity: .6;
      }
    }

    .dz-error-message {
      position: absolute;
      right: 0;
      left: 50%;
      top: 50%;
      width: calc(100% - 60px);
      transform: translateY(-50%) translateX(-50%);
      margin: 0 auto;
      background: white;
      border: 2px solid $orange;

      span {
        color: $orange;
      }
    }

    .dz-success-mark {
      position: absolute;
      right: 0;
      top: 50%;
      left: auto;
      bottom: auto;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: url("../../../assets/img/common/success-mark.svg") center/contain no-repeat;

      svg {
        display: none;
      }
    }


    .dz-error-mark {
      position: absolute;
      right: 0;
      top: 50%;
      left: auto;
      bottom: auto;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: url("../../../assets/img/common/error-mark.svg") center/contain no-repeat;
      //background: url("../../../assets/img/common/success-mark.svg") center/contain no-repeat;

      svg {
        display: none;
      }
    }


    .dz-progress {
      /*position: absolute!important;
      right: 0!important;
      top: 50%!important;
      left: auto!important;
      bottom: auto!important;
      width: 20px!important;
      height: 20px!important;
      margin: 0!important;
      border-radius: 50%;
      //background: url("../../../assets/img/common/progress-mark.svg") center/contain no-repeat!important;
      background: url("../../../assets/img/common/success-mark.svg") center/contain no-repeat;*/

      position: absolute;
      right: 0;
      top: 50%;
      left: auto;
      bottom: auto;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      margin-top: 0;
      border-radius: 50%;
      //background: url("../../../assets/img/common/error-mark.svg") center/contain no-repeat;
      //background: url("../../../assets/img/common/success-mark.svg") center/contain no-repeat;
      //background: url("../../../assets/img/common/progress-mark.svg") center/contain no-repeat!important;
      //background: url("../../../assets/img/common/success-mark-grey.svg") center/contain no-repeat!important;
      background: url("../../../assets/img/common/success-mark.svg") center/contain no-repeat!important;
      /*animation: rotate-custom-dz .9s infinite cubic-bezier(.63,1.11,.73,.55);*/

      svg {
        display: none;
      }

      > span {
        display: none;
      }
    }

    @keyframes rotate-custom-dz {
      0% {
        transform: translateY(-50%) rotate(0);
      }

      100% {
        transform: translateY(-50%) rotate(360deg);
      }
    }


    .dz-preview.dz-success .dz-success-mark {
      animation: passing-through-custom 3s cubic-bezier(0.77, 0, 0.175, 1);
    }

    .dz-preview.dz-complete .dz-success-mark,
    .dz-preview.dz-error .dz-error-mark {
      opacity: 1;
    }

    @keyframes passing-through-custom {
      0% {
        opacity: 0;
        transform: translateY(-50%);
      }

      30%, 70% {
        opacity: 1;
        transform: translateY(-50%);
      }

      100% {
        opacity: 1;
        transform: translateY(-50%);
      }
    }
  }

  .drop-zone-bg{
    .dz-message{
      background: #F8F4EE;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .dz-remove{
      background-image: url("../../../assets/img/UI-group/delete-outlone-icon.svg");
    }

    &.custom-drop-zone .dz-preview {
      border-bottom: 1px solid #F8F4EE;
      margin-bottom: 12px;
    }

    .dz-size{
      margin-right: 14%;
    }

    .error-field{
      margin-top: 5px;
    }
  }

</style>
