<template>
  <div class="fragment">

    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>{{$t('fba_productsStep.localization_value.value')}}</b></div>
    </div>



<!--    <template-->
<!--        v-for="(product, index) in actualProducts">-->
<!--      <CellsTable-->
<!--          :key="index + 'cells'"-->
<!--          :pevCells="pevItem && _.has(actualProductsPrev, index) ? actualProductsPrev[index].cells : []"-->
<!--          :itemCells="product.cells"-->
<!--      />-->
<!--      <ProductTable-->
<!--          :key="index + 'product'"-->
<!--          :quantity="true"-->
<!--          :pevItem="pevItem && _.has(actualProductsPrev, index) ? actualProductsPrev[index] : pevItem"-->
<!--          :item="product"-->
<!--      />-->
<!--    </template>-->

<!--    <template v-if="pevItem && deletedProducts.length > 0">-->
<!--      <template v-for="(product, index) in deletedProducts">-->

<!--        <CellsTable-->
<!--            :key="index + 'cellsPrev'"-->
<!--            :pevCells="product.cells"-->
<!--            :itemCells="null"-->
<!--        />-->
<!--        <ProductTable-->
<!--            :key="index + 'product0'"-->
<!--            :quantity="true"-->
<!--            :pevItem="product"-->
<!--            :item="null"-->
<!--        />-->
<!--      </template>-->

<!--    </template>-->

    <template v-for="(product, index) in item.history.product_entities">
      <CellsTable
          :key="index + 'cells'"
          :pevCells="pevItem && _.has(pevItem.history.product_entities, index) ? pevItem.history.product_entities[index].cells : pevItem"
          :itemCells="product.cells"
      />
      <ProductTable
          :key="index + 'product'"
          :quantity="true"
          :pevItem="pevItem && _.has(pevItem.history.product_entities, index) ? pevItem.history.product_entities[index] : pevItem"
          :item="product"
      />
    </template>
    <template v-if="pevItem && pevItem.history.product_entities.length > item.history.product_entities.length">
      <template v-for="(product, index) in pevItem.history.product_entities">
        <template
            v-if="index > item.history.product_entities.length - 1">
          <CellsTable
              :key="index + 'cellsPrev'"
              :pevCells="product.cells"
              :itemCells="[]"
          />
          <ProductTable
              :key="index + 'product0'"
              :quantity="true"
              :pevItem="product"
              :item="null"
          />
        </template>
      </template>
    </template>



    <div class="history-table__sub-row">
      <div class="history-table__sub-col w-100 mw-100"><b>Sets</b></div>
    </div>
    <template v-for="(set, indexSet) in item.history.fba_sets">
      <div class="history-table__sub-row"
           :key="indexSet + 'setName'">
        <div class="history-table__sub-col w-100 mw-100"><b>Set {{set.sets_count}}</b></div>
      </div>

      <div class="history-table__sub-row"
           :key="indexSet + 'setSku'">
        <div class="history-table__sub-col">{{$t('common_sku.localization_value.value')}}</div>
        <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.fba_sets.'+indexSet+'.set_sku'"/></div>
        <div class="history-table__sub-col"><ValueHelper :value="set" :deep="'set_sku'"/></div>
      </div>



      <template v-for="(product, indexProduct) in set.product_entities">

        <CellsTable
            :key="indexSet + indexProduct + 'setCells1'"
            :pevCells="pevItem && _.has(pevItem.history.fba_sets, indexSet) && _.has(pevItem.history.fba_sets[indexSet].product_entities, indexProduct) ? pevItem.history.fba_sets[indexSet].product_entities[indexProduct].cells : null"
            :itemCells="product.cells"
        />

        <ProductTable
            :key="indexSet + 'productInSet' + indexProduct"
            :quantity="true"
            :pevItem="pevItem && _.has(pevItem.history.fba_sets, indexSet) && _.has(pevItem.history.fba_sets[indexSet].product_entities, indexProduct) ? pevItem.history.fba_sets[indexSet].product_entities[indexProduct] : null"
            :item="product"
        />
      </template>

      <template v-if="pevItem && _.has(pevItem.history.fba_sets, indexSet) && pevItem.history.fba_sets[indexSet].product_entities.length > set.product_entities.length">
        <template v-for="(product, indexProduct) in pevItem.history.fba_sets[indexSet].product_entities">

          <CellsTable
              :key="indexProduct + 'setCellsPrev'"
              :pevCells="product.cells"
              :itemCells="null"
          />
          <ProductTable
              v-if="indexProduct > set.product_entities.length - 1"
              :key="indexSet + 'productInSetPrev' + indexProduct"
              :quantity="true"
              :pevItem="product"
              :item="null"
          />
        </template>
      </template>
    </template>

    <template v-if="pevItem && pevItem.history.fba_sets.length > item.history.fba_sets.length">
      <template v-for="(set, indexSet) in pevItem.history.fba_sets">
        <template v-if="indexSet > item.history.fba_sets.length - 1">
          <div class="history-table__sub-row"
               :key="indexSet + 'setNamePrev'">
            <div class="history-table__sub-col w-100 mw-100"><b>Set {{Number(indexSet) + 1}}</b></div>
          </div>
          <div class="history-table__sub-row"
               :key="indexSet + 'setSkuPrev'">
            <div class="history-table__sub-col">{{$t('common_sku.localization_value.value')}}</div>
            <div class="history-table__sub-col"><ValueHelper :value="set" :deep="'set_sku'"/></div>
            <div class="history-table__sub-col"><ValueHelper :value="null"/></div>
          </div>
          <template v-for="(product, indexProduct) in set.product_entities">
            <ProductTable
                :key="indexSet + 'productInSetPrev' + indexProduct"
                :quantity="true"
                :pevItem="product"
                :item="null"
            />
          </template>
        </template>
      </template>
    </template>



    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_comment.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.comment'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.comment'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fba_skladUsaFee.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <ValueHelper v-if="getStatusItemPrev === 'fba_statusCompleted'" :value="pevItem" :deep="'history.order_fee'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/>
        <span v-else>—</span>
      </div>
      <div class="history-table__sub-col">
        <ValueHelper v-if="getStatusItem === 'fba_statusCompleted'" :value="item" :deep="'history.order_fee'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/>
        <span v-else>—</span>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fba_packaging.localization_value.value')}}</div>
      <div class="history-table__sub-col">
        <ValueHelper v-if="getStatusItemPrev === 'fba_statusCompleted'" :value="pevItem" :deep="'history.packing_cost'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/>
        <span v-else>—</span>
      </div>
      <div class="history-table__sub-col">
        <ValueHelper v-if="getStatusItem === 'fba_statusCompleted'" :value="item" :deep="'history.packing_cost'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/>
        <span v-else>—</span>
      </div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fba_setCost.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.amazon_sets_cost'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.amazon_sets_cost'" :costType="true" :costCurrencyType="'dollar'" :replaceValue="'0'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fba_orderStatus.localization_value.value')}}</div>
      <div class="history-table__sub-col"><span v-if="pevItem && getStatusItemPrev">{{$t(getStatusItemPrev + '.localization_value.value')}}</span><span v-else>—</span></div>
      <div class="history-table__sub-col">{{$t(getStatusItem + '.localization_value.value')}}</div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('fba_shippingDate.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.delivery_date'" :date="true" :dateType="'DD MMM, YYYY'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_weightLb.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.imperial.weight_lb'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.imperial.weight_lb'"/></div>
    </div>

    <div class="history-table__sub-row">
      <div class="history-table__sub-col">{{$t('common_weightOz.localization_value.value')}}</div>
      <div class="history-table__sub-col"><ValueHelper :value="pevItem" :deep="'history.order_dimensions.imperial.weight_oz'"/></div>
      <div class="history-table__sub-col"><ValueHelper :value="item" :deep="'history.order_dimensions.imperial.weight_oz'"/></div>
    </div>

    <PackagingTable
        :pevItem="pevItem ? {packaging: pevItem.history.order_dimensions} : pevItem"
        :item="{packaging: item.history.order_dimensions}"
        :name="false"
        :weight="false"
    />

  </div>
</template>

<script>

import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ProductTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/ProductTable/PackagingTable";
import {FBA_ORDER_STATUSES} from "@/staticData/staticVariables";
import PackagingTable
  from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/PackagingTable/PackagingTable";
import CellsTable from "@/components/coreComponents/Popups/HistoryOfChangesPopup/chunks/CellsTable/CellsTable";

export default {
  name: "FBAType",

  components: {CellsTable, PackagingTable, ProductTable, ValueHelper},

  props: {
    pevItem: Object,
    item: Object,
  },

  computed: {
    getStatusItem() {
      return this.item.history.status === FBA_ORDER_STATUSES.FBA_STATUS_IN_PROGRESS.value ? 'fba_statusInProgress' : 'fba_statusCompleted'
    },
    getStatusItemPrev() {
      if(!this.pevItem) return false
      return this.pevItem.history.status === FBA_ORDER_STATUSES.FBA_STATUS_IN_PROGRESS.value ? 'fba_statusInProgress' : 'fba_statusCompleted'
    },
  },

  data () {
    return {
      actualProducts: [],
      actualProductsPrev: [],
      deletedProducts: [],
    }
  },

  mounted() {
    // let clonePrev = this._.clone(this.pevItem.history.product_entities)
    // let cloneItem = this._.clone(this.item.history.product_entities)
    // cloneItem.map(product => {
    //   let productPrevIndex = this._.findIndex(clonePrev, {product_id: product.product_id})
    //   if(productPrevIndex > -1) {
    //     this.actualProducts.push(product)
    //     this.actualProductsPrev.push(clonePrev[productPrevIndex])
    //     clonePrev.splice(productPrevIndex, 1)
    //   }
    // })
    //
    // this.deletedProducts = clonePrev
  }

}
</script>

<style scoped>

</style>