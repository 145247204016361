import {API} from "../../axios/axiosMainUrl";

export const myPayments = {
  state: {
    myPayments: [],
    myPaymentsItem: {},
    myPaymentsCommonList: [],
    myPaymentsBtn: false,
    myPaymentsLoading: true,
    nextMyPaymentsPage: false,
    myPaymentsForPage: 25,
    myPaymentsFilter: '',
  },

  mutations: {
    setMyPaymentsFilter: (state, payload) => {
      state.myPaymentsFilter = payload
    },

    setMyPayments: (state, payload) => {
      if(state.nextMyPaymentsPage) {
        state.myPayments = state.myPayments.concat(payload.myPayments)
      } else {
        state.myPayments = payload.myPayments
      }
    },

    setMyPaymentsCommonList: (state, payload) => {
      state.myPaymentsCommonList = payload.myPaymentsCommonList
    },

    setNextMyPaymentsPage: (state, payload) => {
      state.nextMyPaymentsPage = payload
    },

    setMyPaymentsItem: (state, payload) => {
      state.myPaymentsItem = payload.myPaymentsItem
    },

    setMyPaymentsLoadingStart: (state) => {
      state.myPaymentsLoading = true
    },

    setMyPaymentsLoadingEnd: (state) => {
      state.myPaymentsLoading = false
    },

    changeMyPaymentsBtn: (state) => {
      state.myPaymentsBtn = true
    },
    successMyPaymentsBtn: (state) => {
      state.myPaymentsBtn = false
    },
  },

  getters: {
    getMyPaymentsFilter: state => {
      return state.myPaymentsFilter
    },

    getMyPaymentsForPage: state => {
      return state.myPaymentsForPage
    },

    getMyPayments: state => {
      return state.myPayments
    },

    getMyPaymentsLoading: state => {
      return state.myPaymentsLoading
    },


    getNextMyPaymentsPage: state => {
      return state.nextMyPaymentsPage
    },

    getMyPaymentsItem: state => {
      return state.myPaymentsItem
    },

    getMyPaymentsCommonList: state => {
      return state.myPaymentsCommonList
    },

    getMyPaymentsBtn: state => {
      return state.myPaymentsBtn
    },
  },

  actions: {

    async fetchMyPayments({commit, getters}, {type, filter = ''}) {
      if(!getters.getNextMyPaymentsPage)
        commit('setMyPaymentsLoadingStart')
      try {
        return await API.get(`/payments/internal/${type}${filter}`).then((response) =>{
          commit('setMyPayments',{myPayments: response.data.data.data})
          commit('setMyPaymentsCommonList',{myPaymentsCommonList: response.data.data})
          commit('setMyPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMyPaymentsLoadingEnd');
        return e;
      }
    },

    async fetchMyPaymentsPayoneer({commit, getters}, {filter = ''}) {
      if(!getters.getNextMyPaymentsPage)
        commit('setMyPaymentsLoadingStart')
      try {
        return await API.get(`/user-balance/transaction${filter}`).then((response) =>{
          commit('setMyPayments',{myPayments: response.data.data.data})
          // commit('setMyPaymentsCommonList',{myPaymentsCommonList: response.data.data})
          commit('setMyPaymentsCommonList',{myPaymentsCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setMyPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMyPaymentsLoadingEnd');
        return e;
      }
    },

    async getMyPayments({commit}, id) {
      // commit('setMyPaymentsLoadingStart');
      try {
        return await API.get(`/payments/internal/${id}`).then((response) =>{
          console.log(response)
          commit('setMyPaymentsItem', {myPaymentsItem: response.data.data})
          // commit('setMyPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMyPaymentsLoadingEnd');
        return e;
      }
    },

    async getMyPaymentsPayoneer({commit}, id) {
      // commit('setMyPaymentsLoadingStart');
      try {
        return await API.get(`/user-balance/transaction/${id}`).then((response) =>{
          console.log(response)
          commit('setMyPaymentsItem', {myPaymentsItem: response.data.data})
          // commit('setMyPaymentsLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setMyPaymentsLoadingEnd');
        return e;
      }
    },

    async printPdfMyPaymentsInvoice({commit}, {type, data}) {
      commit('changeMyPaymentsBtn');
      try {
        return await API.post(`/payments/internal/print-pdf-invoice/${type}`, data).then((response) =>{
          commit('successMyPaymentsBtn');
          return response;
        });
      } catch (e) {
        commit('successMyPaymentsBtn');
        return e;
      }
    },

    // async createMyPayments({commit}, data) {
    //   commit('changeMyPaymentsBtn');
    //   try {
    //     return await API.post(`/myPayments`, data).then((response) =>{
    //       commit('successMyPaymentsBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successMyPaymentsBtn');
    //     return e;
    //   }
    // },
    //
    // async updateMyPayments({commit}, {id, data}) {
    //   commit('changeMyPaymentsBtn');
    //   try {
    //     return await API.put(`/myPayments/${id}`, data).then((response) =>{
    //       commit('successMyPaymentsBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successMyPaymentsBtn');
    //     return e;
    //   }
    // },
    //
    // async deleteMyPayments({commit}, id) {
    //   try {
    //     return await API.delete(`/myPayments/${id}`).then((response) =>{
    //       console.log(response);
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successMyPaymentsBtn');
    //     return e;
    //   }
    // },

    async getExportMyPayments({commit}, {filter , type, exportType}) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`payments/internal/export/${type}/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getExportMyPaymentsPayoneer({commit}, {filter, exportType}) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`user-balance/transaction/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async getExportMyPaymentsPdf({commit}, data) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`payments/internal/print-pdf-invoice/${data.paymentSystem}/${data.paymentTransactionIds}/${data.date}`, data, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setExportBtnLoadingEnd')
        commit('setLoadingEnd')
        return e;
      }
    },

  }
}
